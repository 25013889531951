import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [

  {
    path: '/login',
    name: 'login',
    meta: {
      isPublic: true,
    },
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/classDetail',
    name: 'classDetail',
    meta: {
      isPublic: true,
      title: '教室详情'
    },
    component: () => import('../views/classDetail/index.vue')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      isPublic: true,
      title: '资源不存在'
    },
    component: () => import('../views/404/404.vue')
  },

]
const asyncRoutes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: '报修列表',
      permissions: ''
    },
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/createRepairs',
    name: 'creareRepairs',
    meta: {
      title: '新建报修',
      permissions: 'mobile.repair.store'
    },
    component: () => import('../views/createRepairs/index.vue')
  },
  {
    path: '/detail/:id',
    name: 'detail',
    meta: {
      title: '报修详情',
      permissions: 'mobile.repair.show'
    },
    component: () => import('../views/detail/index.vue')
  },
  {
    path: '/selectSchool',
    name: 'selectSchool',
    meta: {
      title: '校区选择',
    },
    component: () => import('../views/selectSchool/index.vue')
  },
  { path: '*', redirect: '/404', hidden: true }
]
function hasPermission(permissions, route) {
  if (route.meta && route.meta.permissions) {
    return permissions.includes(route.meta.permissions)
  } else {
    return true
  }
}


let GenerateRoutes = (routes, permissions) => {
  let res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(permissions, tmp)) {
      if (tmp.children) {
        tmp.children = GenerateRoutes(tmp.children, permissions)
      }
      res.push(tmp)
    }
  })
  return res
}
let getAsyncRoutes = (permissions) => {
  let filterRoutes = GenerateRoutes(asyncRoutes, permissions)
  let res = routes.concat(filterRoutes)
  return res
}
const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach(async (to, from, next) => {
  let token = ''
  token = localStorage.getItem('token')
  if (from.path == '/index') {
    window.onscroll = ''
  }
  if (token && !store.state.school) {
    if (to.path == '/index') {
      next('/selectSchool')
    }
  }
  if (!token) {
    if (to.path == '/login') next()
    else {
      store.commit('setPermissions', '')
      store.commit('setSchoolId', '')
      store.commit('setSchool', '')
      next('/login')
    }
  }
  else {
    if (!store.state.permissions) {
      await store.dispatch('getPermissions')
      let routes = getAsyncRoutes(store.state.permissions)
      router.options.routes = routes
      routes.forEach((item) => {
        router.addRoute(item)
      })
      next({ ...to })
    }
  }
  next()
})
export default router
