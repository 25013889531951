import Vue from 'vue'
import Vuex from 'vuex'
import { manager } from '../http/user/user'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showScene: false,
    showRepairFinish: false,
    showOffline: false,
    title: '',
    repairId: '',
    switchSceneList: '',
    schoolId: '',
    school: '',
    permissions: '',
    isAdmin: Boolean,
  },
  mutations: {
    setIsAdmin(state, data) {
      state.isAdmin = data
    },
    setSchoolId(state, data) {
      state.schoolId = data
    },
    setSchool(state, data) {
      state.school = data
    },
    setPermissions(state, data) {
      state.permissions = data
    },
    switchScene(state, switchSceneList) {
      state.showScene = !state.showScene
      if (switchSceneList) {
        state.switchSceneList = switchSceneList
      }
    },
    switchRepairFinish(state, repairId) {
      state.showRepairFinish = !state.showRepairFinish
      if (repairId) {
        state.repairId = repairId
      }
    },
    switchOffline(state) {
      state.showOffline = !state.showOffline
    },
    setTitle(state, value) {
      state.title = value
    }
  },
  actions: {
    async getPermissions({ commit }) {
      const managerRes = await manager()
      commit('setPermissions', managerRes.data.permissions)
      return managerRes
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.localStorage,
      reducer(state) {
        return { school: state.school, schoolId: state.schoolId, isAdmin: state.isAdmin };
      }
    })
  ]
})
