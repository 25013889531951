<template>
	<div id="app" v-if="$route.path != '/'">
		<div id="main">
			<van-nav-bar :title="title" :fixed='true' :placeholder="true" @click-left="onClickLeft"
				v-if="$route.path != '/login'">
				<template #right v-if="title == '报修列表' || title == '场景控制'">
					<van-popover theme="dark" v-model="showPopover" trigger="click" :actions="actions" placement="bottom-end"
						:offset="offset" @select="onSelect">
						<template #reference>
							<img class="header-icon" src="./assets/image/ic_add.png" alt="">
						</template>
					</van-popover>

				</template>
				<template #left v-if="showArrow">
					<img v-if="$route.path == '/createRepairs'" src="./assets/image/ic_listback.png" alt="">
					<img v-else class="header-icon" src="./assets/image/ic_back.png" alt="">
				</template>
			</van-nav-bar>
			<router-view />
		</div>
	</div>
</template>
<script>
import { logout } from './http/user/login'

export default {
	data() {
		return {
			offset: [16, 8],
			showPopover: false,

		}
	},
	computed: {
		showArrow() {
			if (this.$route.path == '/selectSchool') {
				return false
			}
			if (this.$route.path == '/index' && this.$store.state.isAdmin == false) {
				return false
			}
			return true
		},
		title() {
			if (this.$route.path == '/index') {
				return this.$store.state.title
			}
			else {
				return this.$route.meta.title
			}
		},
		actions() {
			return this.$store.state.permissions.includes('mobile.repair.store') ? [
				{ text: '新建报修' },
				{ text: '登出' }
			] : [
				{ text: '登出' }
			]
		}
	},
	methods: {
		onSelect(action) {
			if (action.text == '新建报修') {
				this.createRepairs()
			}
			else {
				this.logout()
			}
		},
		onClickLeft() {
			if (this.$route.path == '/createRepairs') {
				this.$router.replace('/index')
			}
			else if (this.$route.path == '/index') {
				if (this.$store.state.isAdmin) {
					this.$router.replace('/selectSchool')
				}
				else {
					this.logout()
				}
			}
			/* else if (this.$route.path == '/selectSchool') {
				this.logout()
			} */
			else {
				this.$router.back()
			}
		},
		createRepairs() {
			this.$router.push('/createRepairs')
		},
		async logout() {
			await logout()
			localStorage.removeItem('token');
			this.$router.replace('/login')
		}
	},
}
</script>
<style lang="less">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#main {
	max-width: 640px;
	margin: 0 auto;
}

.header-icon {
	width: 24px;
	height: 24px;
	vertical-align: middle;
}
</style>
