import http from '../request'
import md5 from 'js-md5';
export async function login(data) {
  data.password=md5(data.password)
  return http.post('/mobile/login', data)
}
export async function refresh(){
  return http.post('/mobile/refresh')
}
export async function logout(){
  return http.post('/mobile/logout')
}