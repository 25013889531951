import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button, Cell, CellGroup } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { NavBar } from 'vant';
import { Field } from 'vant';
import { Form } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import { Stepper } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { Divider } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Dialog } from 'vant';
import { Empty } from 'vant';
import { Loading } from 'vant';
import { Popover } from 'vant';

Vue.use(Popover);
Vue.use(Loading);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Divider);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Stepper);
Vue.use(Button)
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Form);
Vue.use(Field);
Vue.use(NavBar);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Cell);
Vue.use(CellGroup);


Vue.config.productionTip = false
Vue.directive('has', {
  inserted: function (el, binding, vnode) {
    // 获取页面按钮权限
    let btnPermissionsArr = [];
    if (binding.value) {
      // 如果指令传值，获取指令参数，根据指令参数和当前登录人按钮权限做比较。
      btnPermissionsArr = binding.value;
    } else {
      return
    }
    if (!Vue.prototype.$_has(btnPermissionsArr)) {
      el.parentNode.removeChild(el);
    }
  }
});
// 权限检查方法
Vue.prototype.$_has = function (value) {
  let isExist = false;
  // 获取用户按钮权限
  let btnPermissionsStr = store.state.permissions;
  if (btnPermissionsStr == undefined || btnPermissionsStr == null) {
    return false;
  }
  if (btnPermissionsStr.includes(value)) {
    isExist = true;
  }
  return isExist;
};
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
