import axios from 'axios'
import Vue from 'vue';
import { Toast } from 'vant';
import router from '../router';
import { refresh } from './user/login';

Vue.use(Toast);
let http = axios.create({
  timeout: 1000 * 12
});
http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
http.interceptors.request.use(
  config => {
    // 每次发送请求之前判断vuex中是否存在token        
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
    const token = localStorage.getItem('token');
    token && (config.headers.Authorization = 'Bearer ' + token);
    return config;
  },
  error => {
    console.log(error);
    return Promise.error(error);
  }
)
// 是否正在刷新的标记 -- 防止重复发出刷新token接口
let isRefreshing = false;
// 失效后同时发送请求的容器 -- 缓存接口
let subscribers = [];
// 刷新 token 后, 将缓存的接口重新请求一次
function onAccessTokenFetched(newToken) {
  subscribers.forEach((callback) => {
    callback(newToken);
  });
  // 清空缓存接口
  subscribers = [];
}
// 添加缓存接口
function addSubscriber(callback) {
  subscribers.push(callback);
}
async function refreshToken() {
  await refresh().then((res)=>{
    return res.data
  }).catch((err)=>{
    console.log(err);
    return err
  })
  
}
http.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
    // 否则的话抛出错误
    if (response.status != 200) {
      Toast.fail('操作失败')
      return Promise.reject(response)
    }
    if ([2000].includes(response.data.code)) {
      return Promise.resolve(response.data);
    } else {
      switch (response.data.code) {
        case 3000: {
          Toast.fail(response.data.message)
          break;
        }
        case 3001: {
          // 登录过期对用户进行提示
          // 跳转登录页面        
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
          if (!isRefreshing) {
            isRefreshing = true
            refreshToken().then((res) => {
              localStorage.setItem('token', res.accessToken);
              localStorage.setItem('expiresIn', res.expiresIn)
              localStorage.setItem('refreshExpire', res.refreshExpire)
              onAccessTokenFetched(res.accessToken)
              subscribers = [];
              response.config.headers.Authorization = 'Bearer ' + res.accessToken;
              return http(response.config)
            }).catch((err) => {
              console.log(err);
              localStorage.removeItem('token');
              Toast.fail('登录已过期')
              location.reload();
            }).finally(() => {
              isRefreshing = false
            })
          }
          break;
          /* else {
            return new Promise((resolve) => {
              // 这里是将其他接口缓存起来的关键, 返回Promise并且让其状态一直为等待状态, 
              // 只有当token刷新成功后, 就会调用通过addSubscriber函数添加的缓存接口, 
              // 此时, Promise的状态就会变成resolve
              addSubscriber((newToken) => {
                // 表示用新的token去替换掉原来的token
                response.config.headers.Authorization = `bearer ${newToken}`;
                // 用重新封装的config去请求, 就会将重新请求后的返回
                resolve(http(response.config));
              });
            });
          } */
        }
        case 3002: {
          Toast.fail(response.data.message)
          break;
        }
        default:
          // 其他错误，直接抛出错误提示
          Toast.fail('操作失败')
      }
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码    
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  error => {
    Toast.fail('网络异常')
    return Promise.reject(error.response);
  }
);
export default http